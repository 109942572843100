
  import Vue from 'vue';
  import {Component} from "vue-property-decorator";

  @Component({
    components: {
    }
  })
  export default class DashboardView extends Vue {
    partners = [
      "macon.png", "carpatcement.jpg", "holcim.png", "simcorVar.png", "adeplast.jpg", "nexeTigla.png", 
      "tondach.png", "bramac.png", "bilka.jpg", "baumit.png", "knauf.png", "rigips.png"];
    distribution = [
      "macon_new.jpeg", "carpatcement.jpg"]

  }
