import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import DashboardView from "@/views/DashboardView.vue";
import ServicesView from "@/views/ServicesView.vue";
import ContactUs from "@/views/ContactUs.vue";
import Terms from "@/views/Terms.vue";
import Products from "@/views/Products.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: ContactUs
  },
  {
    path: '/products',
    name: 'products',
    component: Products
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms
  }
]

const router = new VueRouter({
  routes
})

export default router
