import { render, staticRenderFns } from "./ServicesView.vue?vue&type=template&id=8bc90d84&scoped=true&"
var script = {}
import style0 from "./ServicesView.vue?vue&type=style&index=0&id=8bc90d84&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bc90d84",
  null
  
)

export default component.exports