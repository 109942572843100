
import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";

@Component({})
export default class Navigation extends Vue {
  navigateTo (navigationUrl: string) {
    this.$router.push(navigationUrl);
  }
}
